<template>
	<Component
		:is="tag"
		:type="tag === 'button' ? type : null"
		:class="{
			button: true,

			'is-black': isBlack,
			'is-large': isLarge,
			'is-minor': isMinor,
			'is-active': isActive,
			'icon-only': !!icon,
			'with-text': iconWithText,
		}"
		v-bind="attrs"
		@click="onClick"
	>
		<div v-if="icon" class="button__inner">
			<AppIcon :type="icon" />
			<slot v-if="iconWithText" />
			<ScreenReaderText v-else>
				<slot />
			</ScreenReaderText>
		</div>
		<slot v-else-if="isMinor" />
		<div v-else class="button__inner">
			<slot />
		</div>
	</Component>
</template>

<script>
import { mapState } from 'vuex';
import AppIcon from '@/components/AppIcon.vue';
import ScreenReaderText from '@/components/ScreenReaderText.vue';

export default {
	inject: ['parentView'],
	components: {
		AppIcon,
		ScreenReaderText,
	},
	props: {
		name: {
			type: String,
			default: null,
		},
		question: {
			type: String,
			default: null,
		},
		icon: {
			type: String,
			default: null,
		},
		color: {
			type: String,
			default: null,
		},
		type: {
			type: String,
			default: 'button',
		},
		href: {
			type: String,
			default: null,
		},
		newTab: Boolean,
		isActive: Boolean,
		iconWithText: Boolean,

		// Roll styles
		isBlack: Boolean,

		// Shape styles
		isLarge: Boolean,
		isMinor: Boolean,
	},
	computed: {
		...mapState(['currentScreen', 'modals/active']),

		tag() {
			return this.href ? 'a' : 'button';
		},
		attrs() {
			return this.href
				? {
						href: this.href,
						target: this.newTab ? '_blank' : undefined,
				  }
				: {};
		},
	},
	methods: {
		onClick(event) {
			if (this.name) {
				this.$log.event({
					event_type: 'click',
					target: this.name,
					location: this.parentView,
					question_name: this.question,
				});
			}

			this.$sounds.play('button');
			this.$emit('click', event);
		},
	},
};
</script>

<style lang="scss">
.button {
	margin-block: rem(16);
	line-height: 1.2;
	text-decoration: none;
	display: inline-block;
	font-size: rem(16);
	font-weight: $font-weight-bold;
	border-radius: 50em;
	cursor: pointer;
	box-shadow: -1px 3px 0px $color-black;
	outline: none;
	transition: all 0.2s;

	& + & {
		margin-left: rem(8);
	}

	&__inner {
		padding: 0.6em 1.8em;
		border-radius: inherit;
		color: $color-black;
		display: flex;
		align-items: center;
		justify-content: center;
		white-space: nowrap;
		background: $color-off-white;
		border: 2px solid;
		transition: all 0.2s;
	}
	&:hover {
		.button__inner {
			transform: translateY(2px) translateX(-0.5px);
		}
	}
	&:active {
		.button__inner {
			transform: translateY(3px) translateX(-1px);
		}
	}
	&:focus {
		.button__inner {
			border-width: 3px;
			transform: translateY(3px) translateX(-1px);
		}
	}
	&:disabled {
		opacity: 0.2;
	}

	.icon {
		fill: currentColor;
	}

	&.is-black {
		font-size: $font-size-button;
		box-shadow: none;
		min-width: rem(224);

		.button__inner {
			padding: 1.1em 2.2em;
			border: none;
			color: $color-white;
			background: $color-black;
			box-shadow: inset 0 0 0 0;
		}

		&:hover,
		&:active,
		&.is-active {
			.button__inner {
				background: #5451c7;
				transform: none;
			}
		}
		&:focus {
			.button__inner {
				background: $color-white;
				color: $color-black;
				box-shadow: inset 0 0 0 3px;
				transform: none;
			}
		}
	}
	&.icon-only {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		border-radius: 100em;
		min-width: 0;
		font-size: rem(14);

		.button__inner {
			padding: 0;
			@include size(40);
		}

		&.with-text {
			.button__inner {
				width: auto;
				padding: 0 rem(10);
			}
			.icon {
				margin-right: rem(10);
			}
		}

		&.is-large {
			.button__inner {
				@include size(60);
			}
			.icon {
				width: 1.8em;
				height: 1.8em;
			}
		}
	}
	&.is-minor {
		padding: 0.75em 1.5em;
		font-size: $font-size-secondary;
		font-weight: $font-weight-bold;
		text-decoration: underline;
		box-shadow: inset 0 0 0 0;

		&:hover,
		&:focus {
			text-decoration: none;
		}
		&:hover {
			background: rgba(#000, 0.1);
		}
		&:focus {
			box-shadow: inset 0 0 0 3px;
		}
	}
	&.is-style-bubble {
		min-width: 0;

		.button__inner {
			padding: 0;
			font-family: $font-family-heading;
			font-size: rem(21);
			font-weight: $heading-weight;
			@include size(44);
			box-shadow: inset -3px -3px 0 #c4c4c4;
		}
	}
}

.button-stack {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	.button {
		margin-bottom: 0;
	}

	.button + .button {
		margin-left: 0;
	}
}
</style>
