<template>
	<div class="end">
		<AppScreen name="end" class="thanks">
			<div class="thanks__content">
				<div v-html="content" />

				<div class="button-stack">
					<BaseButton
						name="summary"
						is-black
						@click="showModal('summary')"
					>
						{{ $l10n('view-summary') }}
					</BaseButton>

					<BaseButton name="restart" is-minor @click="$emit('done')">
						{{ $l10n('restart') }}
					</BaseButton>
				</div>
			</div>
			<div class="thanks__graphic" />
		</AppScreen>
		<BaseModal name="summary" class="summary" body>
			<h2>{{ $l10n('summary') }}</h2>
			<DatapointSummary
				v-for="datapoint in datapoints"
				:key="datapoint.id"
				v-bind="datapoint"
			/>
			<div v-if="summary" v-html="summary" />
		</BaseModal>
	</div>
</template>

<script>
import AppScreen from '@/components/AppScreen.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseModal from '@/components/BaseModal.vue';
import DatapointSummary from '@/components/DatapointSummary.vue';

export default {
	components: {
		AppScreen,
		BaseButton,
		BaseModal,
		DatapointSummary,
	},
	inheritAttrs: false,
	props: {
		content: {
			type: String,
			required: true,
		},
		summary: {
			type: String,
			required: true,
		},
		datapoints: {
			type: Array,
			required: true,
		},
	},
};
</script>

<style lang="scss">
.thanks {
	&__content {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__graphic {
		flex: none;
		align-self: stretch;
		margin: 0 calc(#{$screen-padding} * -1) calc(#{$screen-padding} * -1);
		height: 25vh;
		max-height: 427px;
		background: url(../assets/images/end-decor.png) center top no-repeat;
		background-size: auto 100%;
	}

	&__video {
		position: relative;
		background: black;
		flex: 0;
		align-self: stretch;
		margin: 0 calc(#{$screen-padding} * -1) calc(#{$screen-padding} * -1);
		@include with-aspect(16, 9);

		video {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		@include breakpoint($min-width: 500) {
			margin: 0 auto;
			width: 100%;
			max-width: 640px;
		}
	}
}

.summary {
	.modal__frame {
		background: $color-cyan;
	}
}
</style>
