var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
		slider: true,
		'with-tooltip': !!_vm.tooltip,
		'with-submit': _vm.withSubmit,
		'is-dragging': _vm.dragging,
	},style:({ '--position': (_vm.position + "px") })},[_c('label',{staticClass:"screen-reader-text",attrs:{"for":_vm.uniqueId}},[_vm._v(" "+_vm._s(_vm.$l10n(_vm.a11yLabel))+" ")]),_c('input',{staticClass:"screen-reader-text",attrs:{"type":"range","id":_vm.uniqueId,"max":_vm.max,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":_vm.a11yUpdate}}),(_vm.tooltip)?_c('div',{ref:"tooltip",staticClass:"slider-tooltip"},[_vm._v(" "+_vm._s(_vm.tooltip)+" "),_c('span',{staticClass:"slider-tooltip__arrow"})]):_vm._e(),_c('div',{staticClass:"slider-container"},[_c('div',{ref:"bar",staticClass:"slider-bar",on:{"mousedown":function($event){return _vm.enableDrag($event, 0)},"touchstart":function($event){return _vm.enableDrag($event, 0)}}},[_c('div',{staticClass:"slider-fill"}),(_vm.label)?_c('p',{class:{
					'slider-label': true,
					hidden: _vm.hideLabel,
				}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"slider-thumb"},[_c('div',{staticClass:"slider-thumb__inner"},[_c('AppIcon',{attrs:{"type":"drag"}})],1)])]),(_vm.withSubmit)?_c('BaseButton',{staticClass:"slider-submit",attrs:{"icon":"check","is-large":""},on:{"click":function($event){return _vm.$emit('confirm', _vm.value)}}},[_vm._v(" "+_vm._s(_vm.$l10n('submit'))+" ")]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }