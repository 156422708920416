<template>
	<transition>
		<div
			:class="{
				modal: true,
				'is-open': isOpen,
				[className]: className,
			}"
			tabindex="-1"
			v-show="isOpen"
			@keyup.esc="close"
			@click="maybeClose"
		>
			<div class="modal__frame">
				<div class="modal__body" v-if="content">
					<div v-html="content" />
					<BaseButton name="close" is-black @click="close">
						{{ $l10n('close') }}
					</BaseButton>
				</div>
				<div class="modal__body" v-else-if="body">
					<slot />
				</div>
				<slot v-else />

				<BaseButton
					name="close"
					:location="name"
					icon="close"
					is-black
					class="modal__close"
					@click="close"
				>
					{{ $l10n('close') }}
				</BaseButton>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import BaseButton from '@/components/BaseButton.vue';

export default {
	components: {
		BaseButton,
	},
	inheritAttrs: false,
	props: {
		name: {
			type: String,
			required: true,
		},
		body: Boolean,
		className: {
			type: String,
			default: '',
		},
		content: {
			type: String,
			default: null,
		},
		autoShow: Boolean,
	},
	computed: mapState('modals', {
		isOpen(state) {
			return state.active.includes(this.name);
		},
	}),
	methods: {
		...mapMutations('modals', {
			close(commit) {
				this.$emit('close');
				commit('hide', this.name);
			},
		}),
		maybeClose(e) {
			if (e.target === this.$el) {
				this.close();
			}
		},
	},
	watch: {
		isOpen(isOpen) {
			if (isOpen) {
				this.$emit('show');
				this.$nextTick(() => this.$el.focus());
			} else {
				this.$el.blur();
				this.$emit('hide');
			}

			this.$emit('toggle', isOpen);
		},
	},
	mounted() {
		if (this.autoShow) {
			this.showModal(this.name);
		}
	},
	provide() {
		return {
			parentView: this.name,
		};
	},
};
</script>

<style lang="scss">
.modal {
	position: fixed;
	z-index: 20;
	@include cover;
	backdrop-filter: blur(10px);

	&__frame {
		padding-block: rem(90) rem(120);
		position: fixed;
		z-index: 22;
		@include cover;
		background: $color-purple;
		overflow: auto;
	}

	&__body {
		padding: 0 rem(32);
		width: 100%;
		max-width: rem(700);
		margin: 0 auto;

		img {
			display: block;
			max-width: 100%;
			height: auto;
		}
	}
	&__close {
		position: fixed;
		top: 0;
		right: 0;
		margin: rem(16);
	}

	&.v-enter-active,
	&.v-leave-active {
		overflow: hidden;
		transition: opacity 0.2s, visibility 0.6s, backdrop-filter 0.2s;

		.modal__frame {
			transition: transform 0.4s ease-out;
		}
	}

	&.v-enter-active {
		.modal__frame {
			transition-delay: 0.2s;
		}
	}

	&.v-leave-active {
		transition-delay: 0.4s, 0s;
	}

	&.v-enter,
	&.v-leave-to {
		opacity: 0;
		visibility: hidden;
		backdrop-filter: blur(0px);

		.modal__frame {
			transform: translateY(100vh);
		}
	}

	@media screen and (min-height: 650px) and (min-width: 600px) {
		&__close {
			margin: rem(24);
		}
	}
}
</style>
