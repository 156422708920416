<template>
	<AppScreen :name="name" class="slides">
		<div
			class="slides__track"
			ref="container"
			:style="{ '--count': slides.length }"
			@scroll="updatePosition"
		>
			<AppScreen
				v-for="(slide, index) in slides"
				:key="index"
				ref="slides"
				class="slide"
			>
				<div v-if="slide.photo" class="slide__photo">
					<img v-bind="slide.photo" />
				</div>
				<div class="slide__content" v-html="slide.content" />
			</AppScreen>
		</div>
		<div class="slides__nav">
			<BaseButton
				:name="atEnd ? 'done' : 'next'"
				is-black
				@click="nextSlide"
			>
				{{ nextLabel }}
			</BaseButton>
			<div v-if="slides.length > 1" class="slides__pagination">
				<button
					v-for="(slides, index) in slides"
					:key="index"
					:class="{
						'slides__pagination-dot': true,
						'is-current': position === index,
					}"
					@click="gotoSlide(index)"
				>
					{{ $l10n('goto-slide', $l10n(index + 1)) }}
				</button>
			</div>
		</div>
	</AppScreen>
</template>

<script>
import AppScreen from '@/components/AppScreen.vue';
import BaseButton from '@/components/BaseButton.vue';

export default {
	components: {
		AppScreen,
		BaseButton,
	},
	props: {
		name: {
			type: String,
			default: null,
		},
		slides: {
			type: Array,
			required: true,
		},
		doneLabel: {
			type: String,
			default: 'continue',
		},
	},
	emits: ['done'],
	data() {
		return {
			position: 0,
		};
	},
	computed: {
		atEnd() {
			return this.position >= this.slides.length - 1;
		},
		nextLabel() {
			return this.atEnd ? this.$l10n(this.doneLabel) : this.$l10n('next');
		},
	},
	methods: {
		gotoSlide(index) {
			const { container, slides } = this.$refs;

			const slide = slides[index];
			if (slide) {
				container.scrollLeft = slide.$el.offsetLeft;
			}
		},
		nextSlide() {
			if (this.atEnd) {
				this.$emit('done');
				return;
			}

			this.gotoSlide(this.position + 1);
		},
		updatePosition(event) {
			const offset = Math.round(event.target.scrollLeft);
			const { slides } = this.$refs;

			let index = 0;
			for (let i = slides.length - 1; i >= 0; i--) {
				const slide = slides[i].$el;
				if (slide.offsetLeft + slide.offsetWidth / 2 >= offset) {
					index = i;
				}
			}

			this.position = index;
		},
	},
	provide() {
		return {
			parentView: this.name,
		};
	},
};
</script>

<style lang="scss">
.slides,
.slide {
	padding: 0;
}

.slides {
	display: block;
	overflow: hidden;

	&__track {
		display: grid;
		grid-template-columns: repeat(var(--count, 1), 1fr);
		overflow: auto hidden;
		height: 100%;
		scroll-behavior: smooth;
		scroll-snap-type: x mandatory;
	}

	&__nav {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 0 $screen-padding $screen-padding;
		margin: 0;

		.button {
			margin: 0 auto 4px;

			&:last-child {
				margin-bottom: rem(16);
			}
		}
	}

	&__pagination {
		background: $color-beige;
		padding: rem(12) rem(16);
		margin-bottom: rem(-12);
		border-radius: rem(100);
		margin-inline: auto;
		display: flex;
		align-items: center;
		justify-content: center;

		&-dot {
			font: 0/0 a;
			position: relative;
			@include size(6);
			border: 1px solid;
			border-radius: 50%;

			&:not(:first-child) {
				margin-left: rem(4);
			}

			&.is-current {
				background: $color-black;
			}
		}
	}
}

.slide {
	text-align: left;
	align-items: stretch;
	justify-content: flex-start;
	scroll-snap-align: start;
	overflow: auto;

	img {
		display: block;
		max-width: 100%;
		height: auto;
		margin: 0 auto;
	}

	&__photo {
		padding: rem(90) rem(32) 0;
	}

	&__content {
		flex: 1;
		padding: rem(24) rem(32) rem(120);

		> * {
			max-width: rem(560);
			margin-inline: auto;
		}

		&:first-child {
			padding-top: $toolbar-spacing;
		}
	}

	.intro & {
		text-align: center;
		justify-content: center;
		overflow: hidden;

		&__photo {
			position: relative;
			flex: none;
			height: 215px;

			img {
				position: absolute;
				inset: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		&__content {
			display: flex;
			flex-direction: column;
			align-items: stretch;
			justify-content: flex-start;
			overflow: auto;

			> * {
				max-width: rem(560);
				margin-inline: auto;
				margin-top: 0;
			}

			> :first-child {
				margin-top: auto;
			}
			> :last-child {
				margin-bottom: auto;
			}
		}
	}
}
</style>
