<template>
	<div
		:class="{
			slider: true,
			'with-tooltip': !!tooltip,
			'with-submit': withSubmit,
			'is-dragging': dragging,
		}"
		:style="{ '--position': `${position}px` }"
	>
		<label class="screen-reader-text" :for="uniqueId">
			{{ $l10n(a11yLabel) }}
		</label>
		<input
			type="range"
			class="screen-reader-text"
			:id="uniqueId"
			:value="value"
			:max="max"
			:disabled="disabled"
			@input="a11yUpdate"
		/>
		<div v-if="tooltip" class="slider-tooltip" ref="tooltip">
			{{ tooltip }}
			<span class="slider-tooltip__arrow" />
		</div>
		<div class="slider-container">
			<div
				ref="bar"
				class="slider-bar"
				@mousedown="enableDrag($event, 0)"
				@touchstart="enableDrag($event, 0)"
			>
				<div class="slider-fill"></div>

				<p
					v-if="label"
					:class="{
						'slider-label': true,
						hidden: hideLabel,
					}"
				>
					{{ label }}
				</p>

				<div class="slider-thumb">
					<div class="slider-thumb__inner">
						<AppIcon type="drag" />
					</div>
				</div>
			</div>
			<BaseButton
				v-if="withSubmit"
				icon="check"
				class="slider-submit"
				is-large
				@click="$emit('confirm', value)"
			>
				{{ $l10n('submit') }}
			</BaseButton>
		</div>
	</div>
</template>

<script>
import AppIcon from '@/components/AppIcon.vue';
import BaseButton from '@/components/BaseButton.vue';

export default {
	components: {
		AppIcon,
		BaseButton,
	},
	model: {
		prop: 'value',
		event: 'input',
	},
	emits: ['input', 'confirm', 'dragged'],
	props: {
		value: {
			type: Number,
			default: 0,
		},
		max: {
			type: Number,
			default: 100,
		},
		tooltip: {
			type: String,
			default: null,
		},
		label: {
			type: String,
			default: null,
		},
		a11yLabel: {
			type: String,
			default: 'drag-hint',
		},
		disabled: Boolean,
		hideLabel: Boolean,
		withSubmit: Boolean,
	},
	data() {
		return {
			dragging: true,
			position: 0,
			lastValue: this.value,
		};
	},
	watch: {
		value(newValue) {
			if (newValue !== this.lastValue) {
				this.updatePosition(newValue);
				this.lastValue = newValue;
			}
		},
	},
	mounted() {
		// Non-reactive props
		this.method = null;

		// Initialize with position based on current layout/value
		this.updatePosition(this.lastValue);

		// Allow dragging while cursor is outside of bar
		window.addEventListener('mousemove', this.handleDrag);
		window.addEventListener('touchmove', this.handleDrag);

		// Allow dragging to stop when let go anywhere
		window.addEventListener('mouseup', this.disableDrag);
		window.addEventListener('touchend', this.disableDrag);
	},
	methods: {
		a11yUpdate({ target }) {
			this.$emit('input', target.valueAsNumber);
			this.$emit('dragged');
		},
		getBarSizing() {
			// Get the current bar position and size
			const bar = this.$refs.bar.getBoundingClientRect();

			// Calculate the min/max position, accounting for 50% border radius "padding"
			const min = bar.height / 2;
			const max = bar.width - min * 2;

			return {
				x: bar.left,
				min,
				max,
			};
		},
		updatePosition(value) {
			// Get the bar size to calculate from
			const bar = this.getBarSizing();

			// left padding + percentage of (width - padding)
			this.position = bar.min + bar.max * (value / this.max);
		},
		handlePosition(event) {
			// Touch event = get first touch for coordinates
			if (window.TouchEvent && event instanceof window.TouchEvent) {
				if (event.touches.length > 0) {
					event = event.touches[0];
				} else {
					// Fallback to changedTouches for touchend
					event = event.changedTouches[0];
				}
			}

			// Get the bar size to calculate from
			const bar = this.getBarSizing();

			// Get position of mouse relative to inner left of bar shape
			let position = event.clientX - bar.x - bar.min;

			// Cap the position within the range
			position = Math.max(0, Math.min(position, bar.max));

			// Add the left padding to get the true position
			this.position = position + bar.min;

			// Calculate the value as a whole percent of the max value
			const value = Math.round((position / bar.max) * this.max);

			// Update lastValue so value update on input event
			// doesn't adjust position, making it smoother
			this.lastValue = value;

			// update v-model
			this.$emit('input', value);
		},
		enableDrag(event) {
			// Ignore if disabled
			if (this.disabled) {
				return;
			}

			// Skip if method is already set
			if (this.method) {
				return;
			}

			// Record the input method, declare dragging is active
			this.method = event.type === 'touchstart' ? 'touch' : 'mouse';
			this.dragging = true;

			// Move to tapped position
			this.handlePosition(event);

			this.$sounds.play('drag');
		},
		handleDrag(event) {
			// Ignore if disabled
			if (this.disabled) {
				return;
			}

			// check if same input method that started this
			if (event.type.indexOf(this.method) !== 0) {
				return;
			}

			// Ignore if dragging not properly initiatied
			if (!this.dragging) {
				return;
			}

			this.handlePosition(event);
		},
		disableDrag(event) {
			// Ignore if disabled
			if (this.disabled) {
				return;
			}

			// check if same input method that started this
			if (event.type.indexOf(this.method) !== 0) {
				return;
			}

			// Ignore if dragging not properly initiatied
			if (!this.dragging) {
				return;
			}

			// Ignore further mousemoves until restarted
			this.dragging = false;
			this.method = null;

			// One last position update
			this.handlePosition(event);
			this.updatePosition(this.value);

			this.$sounds.play('answer');

			this.$emit('dragged');
		},
	},
};
</script>

<style lang="scss">
$height: 60px;
// Slightly saner than updating [style] in 3 places
$position: var(--position, #{$height/2});

.slider {
	position: relative;
	font-size: rem(14);
	margin: 0 auto;

	&.with-tooltip {
		margin-top: 50px;
	}

	&-tooltip,
	&-fill,
	&-thumb {
		.is-dragging & {
			transition: none;
		}
	}

	&-tooltip,
	&-thumb {
		left: $position;
		transition: left 0.2s ease-out;
	}

	&-tooltip {
		position: absolute;
		z-index: 1;
		padding: rem(5) rem(15);
		display: inline-block;
		font-size: rem(24);
		font-weight: $font-weight-bold;
		background: $color-beige;
		border: 2px solid;
		border-radius: rem(100);
		bottom: $height;
		transform: translateX(-50%);

		&__arrow {
			width: 0;
			height: 0;
			border-left: rem(12) solid transparent;
			border-right: rem(12) solid transparent;
			border-top: rem(12) solid $color-black;
			position: absolute;
			bottom: rem(-12);
			left: 50%;
			margin-left: rem(-12);

			&:after {
				content: '';
				width: 0;
				height: 0;
				border-left: rem(10) solid transparent;
				border-right: rem(10) solid transparent;
				border-top: rem(10) solid $color-beige;
				position: absolute;
				top: rem(-13);
				left: rem(-10);
			}
		}

		[lang='my'] & {
			font-size: rem(20);
			font-weight: $font-weight-regular;
		}
	}

	&-container {
		display: flex;
	}

	&-bar,
	&-fill {
		box-shadow: 0 0 0 2px $color-black;
		border-radius: calc($height / 2);
	}

	&-bar {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		height: $height;
		background: $color-white;
		z-index: 0;
		position: relative;
		overflow: hidden;
	}

	&-fill,
	&-thumb {
		position: absolute;
		top: 0;
		left: 0;
	}

	&-fill {
		background: $color-gold;
		height: 100%;
		pointer-events: none;
		display: flex;
		align-items: center;
		// add right "padding" rounded edge extends past thumb
		width: calc(#{$position} + #{$height / 2});
		transition: width 0.2s ease-out;
	}

	&-label {
		pointer-events: none;
		padding-inline: $height;
		transition: opacity 0.2s, visibility 0.2s;

		&.hidden {
			opacity: 0;
			visibility: hidden;
		}
	}

	&-thumb {
		@include size($height);
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1;
		left: $position;
		transform: translateX(-50%);
		pointer-events: none; // don't intercept events for bar

		&__inner {
			@include size(48px);
			background: $color-black;
			color: $color-white;
			border-radius: inherit;
			display: flex;
			justify-content: center;
			align-items: center;
		}

		svg {
			fill: currentColor;
		}
	}

	&-submit {
		flex: none;
		margin-block: 0;
		margin-left: rem(10);

		.button__inner {
			background: $color-white;
		}
	}

	input:focus ~ &-container {
		.slider-thumb {
			&__inner {
				background: $color-white;
				color: $color-black;
				box-shadow: inset 0 0 0 3px;
			}
		}
	}
}
</style>
