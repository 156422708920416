export default {
	install(Vue) {
		Vue.prototype.$queue = function (callback, timeout) {
			this.timeouts.push(setTimeout(callback, timeout));
		};

		Vue.mixin({
			mounted() {
				this.timeouts = [];
			},
			destroyed() {
				for (const timeout of this.timeouts) {
					clearTimeout(timeout);
				}
			},
		});
	},
};
