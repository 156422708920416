<template>
	<div class="datapoint">
		<Transition name="fade" mode="out-in">
			<AppScreen
				v-if="step <= $options.STEP_INTRO"
				:key="$options.STEP_INTRO"
				class="datapoint--intro"
			>
				<BounceGraphic
					:image="`number-${number}.svg`"
					:label="$l10n(number)"
					@hidden="doNextStep"
				/>
			</AppScreen>
			<AppScreen
				v-else-if="step <= $options.STEP_ANSWER"
				:key="$options.STEP_GUESS"
				name="datapoint-guess"
				class="datapoint--question"
			>
				<Transition name="fade" mode="out-in">
					<div
						v-if="step === $options.STEP_GUESS"
						key="guess"
						class="datapoint__content datapoint--guess"
					>
						<div class="datapoint__header" v-html="content" />
						<div class="datapoint__footer">
							<BaseSlider
								v-model="guessCount"
								:tooltip="
									guessCount
										? $l10n('percent', $l10n(guessCount))
										: $l10n('guess')
								"
								with-submit
								@confirm="showAnswer(guessCount)"
							/>
						</div>
						<BaseButton
							name="skip"
							class="datapoint__skip"
							@click="showAnswer()"
						>
							{{ $l10n('show-answer') }}
						</BaseButton>
					</div>

					<div
						v-else
						key="result"
						class="datapoint__content datapoint--result"
					>
						<div class="datapoint__header">
							<p
								v-if="current === guessResult"
								class="datapoint__feedback is-correct"
							>
								{{ $l10n('correct') }}
							</p>
							<div v-html="$print(answer, $l10n(current))" />
							<p
								v-if="
									guessResult !== null &&
									current !== guessResult
								"
								class="datapoint__feedback is-incorrect"
							>
								{{
									$l10n(
										'offby',
										$l10n(Math.abs(current - guessResult))
									)
								}}
							</p>
						</div>
						<div class="datapoint__footer">
							<BaseButton
								name="next"
								is-black
								@click="showChange"
							>
								{{ nextLabel }}
							</BaseButton>
						</div>
					</div>
				</Transition>

				<GravityCanvas
					:resolution="2"
					:count="guessCount"
					:shapes="guessShapes"
				/>
			</AppScreen>
			<AppScreen
				v-else-if="step <= $options.STEP_CHANGE"
				:key="$options.STEP_CHANGE"
				name="datapoint-change"
				class="datapoint--change"
			>
				<div class="datapoint__header">
					<div
						v-html="
							$print(
								change,
								`<strong>${$l10n(
									'percent',
									$l10n(Math.abs(current - previous))
								)}</strong>`
							)
						"
					/>
				</div>

				<BarGraph :datum="changeData" />

				<div class="datapoint__footer">
					<BaseButton name="next" is-black @click="doNextStep">
						{{ nextLabel }}
					</BaseButton>
				</div>
			</AppScreen>
			<SlideCarousel
				v-else-if="step <= $options.STEP_MOREINFO"
				:key="$options.STEP_MOREINFO"
				name="datapoint-info"
				:slides="info_slides"
				:done-label="nextLabel"
				@done="doNextStep"
			/>
		</Transition>
	</div>
</template>

<script>
import AppScreen from '@/components/AppScreen.vue';
import BaseButton from '@/components/BaseButton.vue';
import GravityCanvas from '@/components/GravityCanvas.vue';
import BarGraph from '@/components/BarGraph.vue';
import SlideCarousel from '@/components/SlideCarousel.vue';
import BaseSlider from '@/components/BaseSlider.vue';
import BounceGraphic from '../components/BounceGraphic.vue';
import SHAPES from '@/utilities/shapes';

// Step IDs
const STEP_INTRO = -1;
const STEP_GUESS = 0;
const STEP_ANSWER = 1;
const STEP_CHANGE = 2;
const STEP_MOREINFO = 3;
const STEP_COMPLETE = 4;

export default {
	// For template use via $options
	STEP_INTRO,
	STEP_GUESS,
	STEP_ANSWER,
	STEP_CHANGE,
	STEP_MOREINFO,
	STEP_COMPLETE,

	inheritAttrs: false,
	components: {
		AppScreen,
		BaseButton,
		GravityCanvas,
		BarGraph,
		BaseSlider,
		SlideCarousel,
		BounceGraphic,
	},
	props: {
		number: {
			type: Number,
			default: 1,
		},
		name: {
			type: String,
			required: true,
		},
		current: {
			type: Number,
			required: true,
		},
		current_label: {
			type: String,
			required: true,
		},
		previous: {
			type: Number,
			required: true,
		},
		previous_label: {
			type: String,
			required: true,
		},
		content: {
			type: String,
			required: true,
		},
		answer: {
			type: String,
			required: true,
		},
		change: {
			type: String,
			default: null,
		},
		info_slides: {
			type: Array,
			default: () => [],
		},
	},
	emits: ['done'],
	data() {
		return {
			// Progress tracking
			step: STEP_INTRO,

			// The user input, then the off-by value
			guessCount: 0,
			// The saved answer
			guessResult: null,

			// The incrementing change amount to display
			changeValue: 0,
		};
	},
	computed: {
		guessShapes() {
			// Add copies of the same shape
			if (this.guessResult !== null) {
				return SHAPES.slice(0, 1);
			}

			// Add random shapes, except the first one
			return SHAPES.slice(1);
		},

		isIncrease() {
			return this.current > this.previous;
		},
		changeData() {
			return [
				{
					label: this.previous_label,
					value: this.previous,
					face: require(`@/assets/images/trend-before-${Math.ceil(
						Math.random() * 4
					)}.png`),
				},
				{
					label: this.current_label,
					value: this.current,
					face: require(`@/assets/images/trend-after-${Math.ceil(
						Math.random() * 4
					)}.png`),
				},
			];
		},

		nextStep() {
			let next = this.step + 1;
			// No change to show, skip to more info
			if (next === STEP_CHANGE && !this.change) {
				next = STEP_MOREINFO;
			}
			// No info slides, skip to done
			if (next === STEP_MOREINFO && this.info_slides.length === 0) {
				next = STEP_COMPLETE;
			}

			return next;
		},
		nextLabel() {
			if (this.nextStep === STEP_COMPLETE) {
				return this.$l10n('next-question');
			}

			return this.$l10n('continue');
		},
	},
	methods: {
		doNextStep() {
			// No next step, announce completion
			if (this.nextStep === STEP_COMPLETE) {
				return this.$emit('done');
			}

			this.step = this.nextStep;
		},
		showAnswer(value = null) {
			this.guessResult = value;
			this.step++;

			this.$log.answer({
				question_type: 'datapoint',
				question_name: this.name,
				answer_text: value === null ? 'skip' : value.toString(),
				result:
					value === null
						? ''
						: (this.guessResult - this.current).toString(),
			});

			if (this.guessCount === this.current) {
				this.$sounds.play('correct');
				return;
			}

			// Increment/decrement count to correct value
			const increment = this.current > this.guessCount ? 1 : -1;
			const tick = () => {
				this.guessCount += increment;

				if (this.guessCount !== this.current) {
					this.$queue(tick, 100);
				}
			};

			// Delay start of off-by animation
			this.$queue(() => {
				this.$sounds.play('answer');
				tick();
			}, 1000);
		},
		showChange() {
			// No change to show, skip
			if (!this.previous) {
				return this.showMoreInfo();
			}

			this.step = STEP_CHANGE;
		},
	},
};
</script>

<style lang="scss">
.datapoint {
	&--intro {
		padding: 0;
		overflow: hidden;
	}

	&__content {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: stretch;
		justify-content: center;
		max-width: rem(800);
		margin: 0 auto;
	}

	&__header {
		margin-bottom: auto;

		.datapoint--change & {
			max-width: rem(600);
		}

		@include breakpoint($min-width: 700) {
			margin-top: auto;
		}
	}
	&__footer {
		margin-top: auto;

		@include breakpoint($min-width: 700) {
			margin-top: 0;
		}
	}
	&__skip {
		position: absolute;
		top: 0;
		right: 0;
		margin: calc(#{$screen-padding} / 2);
	}
	&__feedback {
		display: inline-block;
		font-family: $font-family-heading;
		font-weight: $heading-weight;
		line-height: 1;
		padding: 0.5em 2em;
		margin: 0;
		border-radius: 2em;

		&.is-correct {
			background: #b1f4d4;
			transform: rotate(-2deg);
		}
		&.is-incorrect {
			background: #def59b;
			transform: rotate(2deg);
		}
	}

	.slider {
		max-width: rem(300);
	}

	&--guess {
		h1,
		h2 {
			font-size: $font-size-headline;
		}
	}

	&--change &__header {
		h2 {
			font-size: $font-size-heading;
		}

		strong {
			color: #699467;
		}
	}
}
</style>
