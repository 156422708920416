var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.tag,_vm._b({tag:"Component",class:{
		button: true,

		'is-black': _vm.isBlack,
		'is-large': _vm.isLarge,
		'is-minor': _vm.isMinor,
		'is-active': _vm.isActive,
		'icon-only': !!_vm.icon,
		'with-text': _vm.iconWithText,
	},attrs:{"type":_vm.tag === 'button' ? _vm.type : null},on:{"click":_vm.onClick}},'Component',_vm.attrs,false),[(_vm.icon)?_c('div',{staticClass:"button__inner"},[_c('AppIcon',{attrs:{"type":_vm.icon}}),(_vm.iconWithText)?_vm._t("default"):_c('ScreenReaderText',[_vm._t("default")],2)],2):(_vm.isMinor)?_vm._t("default"):_c('div',{staticClass:"button__inner"},[_vm._t("default")],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }