var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"datapoint"},[_c('Transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.step <= _vm.$options.STEP_INTRO)?_c('AppScreen',{key:_vm.$options.STEP_INTRO,staticClass:"datapoint--intro"},[_c('BounceGraphic',{attrs:{"image":("number-" + _vm.number + ".svg"),"label":_vm.$l10n(_vm.number)},on:{"hidden":_vm.doNextStep}})],1):(_vm.step <= _vm.$options.STEP_ANSWER)?_c('AppScreen',{key:_vm.$options.STEP_GUESS,staticClass:"datapoint--question",attrs:{"name":"datapoint-guess"}},[_c('Transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.step === _vm.$options.STEP_GUESS)?_c('div',{key:"guess",staticClass:"datapoint__content datapoint--guess"},[_c('div',{staticClass:"datapoint__header",domProps:{"innerHTML":_vm._s(_vm.content)}}),_c('div',{staticClass:"datapoint__footer"},[_c('BaseSlider',{attrs:{"tooltip":_vm.guessCount
									? _vm.$l10n('percent', _vm.$l10n(_vm.guessCount))
									: _vm.$l10n('guess'),"with-submit":""},on:{"confirm":function($event){return _vm.showAnswer(_vm.guessCount)}},model:{value:(_vm.guessCount),callback:function ($$v) {_vm.guessCount=$$v},expression:"guessCount"}})],1),_c('BaseButton',{staticClass:"datapoint__skip",attrs:{"name":"skip"},on:{"click":function($event){return _vm.showAnswer()}}},[_vm._v(" "+_vm._s(_vm.$l10n('show-answer'))+" ")])],1):_c('div',{key:"result",staticClass:"datapoint__content datapoint--result"},[_c('div',{staticClass:"datapoint__header"},[(_vm.current === _vm.guessResult)?_c('p',{staticClass:"datapoint__feedback is-correct"},[_vm._v(" "+_vm._s(_vm.$l10n('correct'))+" ")]):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(_vm.$print(_vm.answer, _vm.$l10n(_vm.current)))}}),(
								_vm.guessResult !== null &&
								_vm.current !== _vm.guessResult
							)?_c('p',{staticClass:"datapoint__feedback is-incorrect"},[_vm._v(" "+_vm._s(_vm.$l10n( 'offby', _vm.$l10n(Math.abs(_vm.current - _vm.guessResult)) ))+" ")]):_vm._e()]),_c('div',{staticClass:"datapoint__footer"},[_c('BaseButton',{attrs:{"name":"next","is-black":""},on:{"click":_vm.showChange}},[_vm._v(" "+_vm._s(_vm.nextLabel)+" ")])],1)])]),_c('GravityCanvas',{attrs:{"resolution":2,"count":_vm.guessCount,"shapes":_vm.guessShapes}})],1):(_vm.step <= _vm.$options.STEP_CHANGE)?_c('AppScreen',{key:_vm.$options.STEP_CHANGE,staticClass:"datapoint--change",attrs:{"name":"datapoint-change"}},[_c('div',{staticClass:"datapoint__header"},[_c('div',{domProps:{"innerHTML":_vm._s(
						_vm.$print(
							_vm.change,
							("<strong>" + (_vm.$l10n(
								'percent',
								_vm.$l10n(Math.abs(_vm.current - _vm.previous))
							)) + "</strong>")
						)
					)}})]),_c('BarGraph',{attrs:{"datum":_vm.changeData}}),_c('div',{staticClass:"datapoint__footer"},[_c('BaseButton',{attrs:{"name":"next","is-black":""},on:{"click":_vm.doNextStep}},[_vm._v(" "+_vm._s(_vm.nextLabel)+" ")])],1)],1):(_vm.step <= _vm.$options.STEP_MOREINFO)?_c('SlideCarousel',{key:_vm.$options.STEP_MOREINFO,attrs:{"name":"datapoint-info","slides":_vm.info_slides,"done-label":_vm.nextLabel},on:{"done":_vm.doNextStep}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }