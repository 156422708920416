<template>
	<div class="bar-graph" :style="{ '--min': min, '--max': max }">
		<div class="bar-graph__axes">
			<div v-for="axis in axes" :key="axis" class="bar-graph__axis">
				<span class="bar-graph__axis-label">
					{{ $l10n('percent', $l10n(axis)) }}
				</span>
			</div>
		</div>
		<div class="bar-graph__bars">
			<div
				v-for="(data, index) in datum"
				:key="data.label"
				class="bar-graph__bar"
			>
				<Transition type="animation">
					<div
						class="bar-graph__bar-shape"
						:style="{
							'--value': data.value,
							'background-image': `url(${data.face})`,
						}"
						v-show="showCount >= index"
					>
						<span class="bar-graph__bar-value">
							{{ $l10n('percent', $l10n(data.value)) }}
						</span>
						<span class="bar-graph__bar-label">
							{{ data.label }}
						</span>
					</div>
				</Transition>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		datum: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			showCount: -1,
		};
	},
	computed: {
		values() {
			return this.datum.map(d => d.value);
		},
		min() {
			return Math.max(
				0,
				Math.floor(Math.min(...this.values) / 10) * 10 - 10
			);
		},
		max() {
			return Math.min(100, Math.ceil(Math.max(...this.values) / 10) * 10);
		},
		step() {
			if (this.max - this.min <= 20) {
				return 5;
			}

			return 10;
		},
		axes() {
			const axes = [];
			for (let i = this.max; i >= this.min; i -= this.step) {
				axes.push(i);
			}

			return axes;
		},
	},
	mounted() {
		this.interval = setInterval(() => {
			if (this.showCount >= this.datum.length - 1) {
				clearInterval(this.interval);
				return;
			}
			this.showCount++;
			this.$sounds.play(`graph-up${this.showCount % 2 ? 2 : 1}`);
		}, 1000);
	},
	destroyed() {
		clearInterval(this.interval);
	},
};
</script>

<style lang="scss">
@keyframes barHeightIn {
	from {
		height: 0;
	}
}

.bar-graph {
	flex: 1;
	position: relative;
	width: 100%;
	font-size: $font-size-small;
	max-height: 21em;
	max-width: 20em;
	margin: rem(30) auto;

	&__axes,
	&__bars {
		position: absolute;
		top: 0.5em;
		left: 2ch;
		right: 0;
		bottom: 1.5em;
		display: flex;
	}
	&__axes {
		flex-direction: column;
		justify-content: space-between;
		align-items: stretch;
	}
	&__axis {
		position: relative;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		line-height: 0;
		text-align: right;

		&-label {
			margin-left: -2ch;
			margin-right: 0.5em;
		}

		&::after {
			content: '';
			flex: 1;
			border-bottom: 1px solid rgba(#000, 0.1);
		}

		&:last-child {
			visibility: hidden;
		}
	}
	&__bars {
		flex-direction: row;
		justify-content: center;
		align-items: flex-end;
		padding-inline: 10px;
		border-bottom: 3px solid;
	}
	&__bar {
		flex: 1;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		text-align: center;
		height: 100%;
		max-width: 82px;
		margin-inline: 8px;

		&-shape {
			position: relative;
			background: #efb9e0 center top no-repeat;
			background-size: 100% auto;
			color: #9065be;
			border-radius: 82px 82px 0 0;
			height: calc(
				((var(--value) - var(--min)) / (var(--max) - var(--min))) * 100%
			);

			&.v-enter-active {
				animation: barHeightIn 0.6s
					cubic-bezier(0.175, 0.885, 0.715, 1.4) both;
			}
		}

		&-value,
		&-label {
			position: absolute;
			left: 0;
			width: 100%;
			padding: 0.2em 0;
		}

		&-value {
			font-family: $font-family-heading;
			font-size: $font-size-heading;
			font-weight: $heading-weight;
			bottom: 100%;
		}
		&-label {
			top: 100%;
			color: #000;
			font-weight: $font-weight-bold;
		}

		&:nth-child(2) &-shape {
			background-color: #9cdb9b;
			color: #699467;
		}
	}
}
</style>
