export default {
	namespaced: true,
	state: {
		muted: true,
	},
	mutations: {
		toggle(state, toggle) {
			if (typeof toggle === 'undefined') {
				toggle = state.muted;
			}

			state.muted = !toggle;
		},
		mute(state) {
			state.muted = true;
		},
		unmute(state) {
			state.muted = false;
		},
	},
};
