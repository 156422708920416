<template>
	<AppScreen name="demosurvey" class="question">
		<div class="question__body">
			<h2>{{ title }}</h2>
			<div
				:class="{
					question__buttons: true,
					'is-style-grid': answers.length > 6,
				}"
			>
				<BaseButton
					v-for="answer in answers"
					:key="answer.id"
					:class="{
						'is-active': selected.includes(answer.id),
					}"
					@click="toggleAnswer(answer.id)"
				>
					{{ answer.text }}
				</BaseButton>
			</div>
		</div>
		<div class="question__footer">
			<BaseButton
				is-black
				is-large
				:disabled="selected.length === 0"
				@click="submitAnswer(selected)"
			>
				{{ $l10n('submit') }}
			</BaseButton>
			<BaseButton is-minor @click="submitAnswer(['abstain'])">
				{{ $l10n('not-disclose') }}
			</BaseButton>
		</div>
	</AppScreen>
</template>

<script>
import AppScreen from './AppScreen.vue';
import BaseButton from './BaseButton.vue';

export default {
	components: {
		AppScreen,
		BaseButton,
	},
	inheritAttrs: true,
	props: {
		name: {
			type: String,
			required: true,
		},
		title: {
			type: String,
			required: true,
		},
		answers: {
			type: Array,
			default: () => [],
		},
		multipleChoice: Boolean,
		noneOption: Boolean,
	},
	data() {
		return {
			selected: [],
		};
	},
	methods: {
		toggleAnswer(answer) {
			if (this.multipleChoice) {
				const set = new Set(this.selected);
				if (set.has(answer)) {
					set.delete(answer);
				} else {
					set.add(answer);
				}
				this.selected = set.values();
			} else {
				this.selected = this.selected.includes(answer) ? [] : [answer];
			}
		},
		submitAnswer(answer) {
			this.$log.demographic({
				question_name: this.name,
				answers: answer,
			});

			this.$emit('submit');
		},
	},
};
</script>

<style lang="scss">
.question {
	background: $color-purple;

	&__body {
		margin: auto;
		width: 100%;
		max-width: rem(360);
	}
	&__buttons {
		max-width: rem(200);
		margin: 2em auto 0;
		display: grid;
		grid-template-columns: 1fr;
		grid-gap: 0.4em;

		&.is-style-grid {
			max-width: rem(280);
			grid-template-columns: repeat(4, 1fr);

			.button {
				grid-column: span 2;

				&:last-child:nth-child(odd) {
					grid-column: 2/4;
				}
			}
		}

		.button {
			margin: 0;

			&.is-active {
				.button__inner {
					background: $color-black;
					color: $color-off-white;
				}
			}
		}
	}
	&__footer {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin: auto auto 0;

		.button + .button {
			margin-left: 0;
		}
	}
}
</style>
