<script>
const PATHS = {
	check: {
		width: 30,
		height: 30,
		paths: [
			'M12,23.5c-0.4,0-0.8-0.1-1.1-0.4l-7-7c-0.6-0.6-0.6-1.5,0-2.1c0.6-0.6,1.5-0.6,2.1,0l5.9,5.9L24.9,6.9c0.6-0.6,1.5-0.6,2.1,0c0.6,0.6,0.6,1.5,0,2.1l-14,14C12.8,23.4,12.4,23.5,12,23.5z',
		],
	},
	close: {
		width: 20,
		height: 20,
		paths: [
			'M16.8,17.8c-0.3,0-0.5-0.1-0.7-0.3L10,11.4l-6.1,6.1c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.4L8.6,10L2.5,3.9c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L10,8.6l6.1-6.1c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L11.4,10l6.1,6.1c0.4,0.4,0.4,1,0,1.4C17.3,17.7,17.1,17.8,16.8,17.8z',
		],
	},
	drag: {
		width: 20,
		height: 20,
		paths: [
			'M0.7,19h2.5V1.7H0.7V19z M8.7,19h2.5V1.7H8.7V19z M16.8,19h2.5V1.7h-2.5V19z',
		],
	},
	info: {
		width: 20,
		height: 20,
		paths: [
			'M7.6,16.5v-1.3L8.9,15V9L7.5,8.8V7.5h3.7V15l1.3,0.2v1.3H7.6z M8.8,5.4V3.5h2.3v1.9H8.8z',
		],
	},
	help: {
		width: 22,
		height: 22,
		paths: [
			'M11,22C4.9,22,0,17.1,0,11S4.9,0,11,0s11,4.9,11,11S17.1,22,11,22z M11,2c-5,0-9,4-9,9c0,5,4,9,9,9c5,0,9-4,9-9C20,6,16,2,11,2z',
			'M10.9,13c-0.4,0-0.8-0.3-0.9-0.7c-0.2-0.5,0.1-1.1,0.6-1.3c0.6-0.2,2.3-1,2.3-2.1c0-0.5-0.2-0.9-0.5-1.3c-0.3-0.4-0.7-0.6-1.2-0.7c-0.5-0.1-0.9,0-1.4,0.2C9.5,7.5,9.2,7.9,9,8.3C8.8,8.9,8.3,9.1,7.8,8.9C7.2,8.8,7,8.2,7.1,7.7C7.5,6.8,8.1,6,8.9,5.5c0.8-0.5,1.8-0.7,2.7-0.5c0.9,0.2,1.8,0.6,2.4,1.4c0.6,0.7,0.9,1.6,0.9,2.6c0,2.6-3.3,3.8-3.7,3.9C11.1,13,11,13,10.9,13z',
			'M11,17c-0.6,0-1-0.4-1-1s0.4-1,1-1h0c0.6,0,1,0.4,1,1S11.6,17,11,17z',
		],
	},
	sound: {
		width: 20,
		height: 20,
		paths: [
			'M18.4,12.7V0L7.1,3.4v10.5c-0.1,0-0.2-0.1-0.3-0.1c-0.8-0.1-1.7,0-2.6,0.3c-0.9,0.3-1.6,0.8-2.1,1.5c-0.5,0.6-0.7,1.4-0.4,2.2c0.4,0.8,1.2,1.2,2,1.3c0.8,0.1,1.7,0,2.6-0.3c0.9-0.3,1.6-0.8,2.1-1.5c0.3-0.3,0.5-0.8,0.5-1.2l0-7.9l7.5-2.3v5.2c-0.1,0-0.2,0-0.3-0.1c-0.8-0.1-1.7,0-2.6,0.3c-0.9,0.3-1.6,0.8-2.1,1.4C11,13.4,10.7,14.2,11,15s1.2,1.2,2,1.3c0.8,0.1,1.7,0,2.6-0.3c0.9-0.3,1.6-0.8,2.1-1.4C18.2,14,18.5,13.4,18.4,12.7z M6.9,16.4c-0.2,0.3-0.7,0.6-1.3,0.9c-0.6,0.2-1.2,0.3-1.6,0.2c-0.4-0.1-0.5-0.2-0.6-0.3c0-0.1-0.1-0.2,0.2-0.6c0.2-0.3,0.7-0.6,1.3-0.9c0.6-0.2,1.2-0.3,1.6-0.2c0.4,0.1,0.5,0.2,0.6,0.3C7.1,15.9,7.1,16,6.9,16.4z M9,6.4V4.6l7.5-2.3v1.8L9,6.4z M15,14.4c-0.6,0.2-1.2,0.3-1.7,0.2c-0.4-0.1-0.5-0.2-0.6-0.2c0,0-0.1-0.2,0.2-0.5c0.3-0.3,0.7-0.7,1.4-0.9c0.6-0.2,1.2-0.3,1.7-0.2c0.4,0.1,0.5,0.2,0.5,0.2v0.2c0,0.1-0.1,0.2-0.2,0.3C16.1,13.8,15.6,14.1,15,14.4z',
		],
	},
	'very-happy': {
		type: 'emote',
		width: 44,
		height: 44,
		paths: [
			'M14.2,25.1v2c1.7,2.7,4.7,4.1,7.8,4c2.9-0.1,5.6-1.5,7.2-4h0v-2H14.2z M22,28.6c-1.6,0.1-3.1-0.5-4.3-1.5h8.2C24.8,28,23.4,28.6,22,28.6z',
			'M34.4,22.8c1.3,0,2.4-1.1,2.4-2.5c0-1.4-1.1-2.5-2.4-2.5c-1.3,0-2.4,1.1-2.4,2.5C32,21.7,33.1,22.8,34.4,22.8z',
			'M10.3,22.8c1.3,0,2.4-1.1,2.4-2.5c0-1.4-1.1-2.5-2.4-2.5c-1.3,0-2.4,1.1-2.4,2.5C7.9,21.7,9,22.8,10.3,22.8z',
		],
	},
	happy: {
		type: 'emote',
		width: 44,
		height: 44,
		paths: [
			'M22.2,30.9c-3.1,0.1-6.1-1.5-7.8-4.3l2.1-1.3c1.2,2,3.4,3.2,5.7,3.1c2.1-0.1,4.1-1.2,5.2-3.1l2.1,1.3C27.9,29.2,25.2,30.8,22.2,30.9z',
			'M34.5,22.7c1.3,0,2.4-1.1,2.4-2.5c0-1.4-1.1-2.5-2.4-2.5c-1.3,0-2.4,1.1-2.4,2.5C32.1,21.6,33.2,22.7,34.5,22.7z',
			'M10.4,22.7c1.3,0,2.4-1.1,2.4-2.5c0-1.4-1.1-2.5-2.4-2.5c-1.3,0-2.4,1.1-2.4,2.5C8,21.6,9.1,22.7,10.4,22.7z',
		],
	},
	neutral: {
		type: 'emote',
		width: 44,
		height: 44,
		paths: [
			'M12.9,25.7h18.2v2.6H12.9V25.7z',
			'M34.7,22.5c1.4,0,2.5-1.1,2.5-2.5c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5C32.3,21.4,33.4,22.5,34.7,22.5z',
			'M10.2,22.5c1.4,0,2.5-1.1,2.5-2.5c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5C7.7,21.4,8.8,22.5,10.2,22.5z',
		],
	},
	upset: {
		type: 'emote',
		width: 44,
		height: 44,
		paths: [
			'M21.5,22.8c3.1,0,6.1,1.7,7.6,4.5L27,28.6c-1.1-2-3.3-3.3-5.6-3.3c-2.1,0-4.1,1.1-5.3,2.9l-2-1.4C15.8,24.3,18.5,22.8,21.5,22.8z',
			'M34.2,22.3c1.3,0,2.4-1.1,2.4-2.5c0-1.4-1.1-2.5-2.4-2.5c-1.3,0-2.4,1.1-2.4,2.5C31.8,21.2,32.8,22.3,34.2,22.3z',
			'M10.1,22.3c1.3,0,2.4-1.1,2.4-2.5c0-1.4-1.1-2.5-2.4-2.5c-1.3,0-2.4,1.1-2.4,2.5C7.6,21.2,8.7,22.3,10.1,22.3z',
		],
	},
	'very-upset': {
		type: 'emote',
		width: 44,
		height: 44,
		paths: [
			'M21.8,23.1c-2.9,0-5.7,1.4-7.3,3.9v2h15v-2C27.9,24.2,25,23.1,21.8,23.1z M21.8,25.6c1.4,0,2.8,0.5,3.9,1.4h-7.9C19,26.1,20.4,25.6,21.8,25.6z',
			'M34.4,22.6c1.3,0,2.4-1.1,2.4-2.5c0-1.4-1.1-2.5-2.4-2.5S32,18.8,32,20.2C32,21.5,33.1,22.6,34.4,22.6z',
			'M10.3,22.6c1.3,0,2.4-1.1,2.4-2.5c0-1.4-1.1-2.5-2.4-2.5c-1.3,0-2.4,1.1-2.4,2.5C7.9,21.5,9,22.6,10.3,22.6z',
		],
	},
};

export default {
	functional: true,
	props: {
		type: {
			type: String,
			required: true,
		},
	},
	render(createElement, { props }) {
		const {
			type,
			width = 16,
			height = 16,
			paths,
		} = PATHS[props.type] || {};

		return createElement(
			'svg',
			{
				class: {
					icon: true,
					[`${type}-icon`]: type,
					[`icon-${props.type}`]: props.type,
				},
				attrs: {
					xmlns: 'http://www.w3.org/2000/svg',
					viewBox: `0 0 ${width} ${height}`,
					'aria-hidden': true,
					width,
					height,
				},
			},
			paths.map(path => createElement('path', { attrs: { d: path } }))
		);
	},
};
</script>

<style lang="scss">
.icon {
	display: block;
	flex: none;
	max-width: 100%;
	max-height: 100%;
}
.emote-icon {
	border-radius: 50%;
	box-shadow: inset -3px -3px 0 #c4c4c4;
}
</style>
