export default [
	{
		path: 'M75.6,0H24.4C10.9,0,0,10.9,0,24.4v42.4c0,13.5,10.9,24.4,24.4,24.4h51.1c13.5,0,24.4-10.9,24.4-24.4V24.4C100,10.9,89.1,0,75.6,0z',
		polygon:
			'0.1,22.6 6,6.6 22.2,0.1 77.8,0.1 93.4,5.9 100,22.6 100,67.6 93.9,84.8 76.7,91.2 22.4,91.2 6.5,85.1 0.1,68.6',
		width: 100,
		height: 92,
		image: require('@/assets/images/character-1.png'),
		resolution: 2,
	},
	{
		path: 'M99.3,46.6c0.1,21.9-16.5,39.8-50.2,39.2C16.8,85.3,0.1,69.6,0,47.8C-0.1,25.9,13.7,10.2,49.1,9.7C83.6,9.2,99.2,24.8,99.3,46.6z',
		polygon:
			'0,46.8 7.5,21.6 34.6,10.9 65.8,10.9 92.5,22.4 99.3,47.8 92.1,72.9 67.1,84.1 33.1,84.1 7.8,72.2',
		width: 100,
		height: 86,
		image: require('@/assets/images/character-2.png'),
		resolution: 2,
	},
	{
		path: 'M29.7,0L29.7,0C13.3,0,0,13.3,0,29.7v40.6C0,86.7,13.3,100,29.7,100h0c16.4,0,29.7-13.3,29.7-29.7V29.7C59.4,13.3,46.1,0,29.7,0z',
		polygon:
			'0,26.5 9.4,5.7 29.7,0 50.7,5.9 59.4,26.9 59.3,72.4 51.7,93.5 29.7,100 9,94.4 0.1,73.2',
		width: 60,
		height: 100,
		image: require('@/assets/images/character-3.png'),
		resolution: 2,
	},
	{
		path: 'M62.8,97.5c-7.6,0-15.1,0-22.7-0.2C33,97.1,25.9,97.1,19,94.7c-1-0.3-2-0.7-3-1.1c-3.8-1.6-7.3-3.8-9.9-7c-2.4-3-3.9-6.6-4.8-10.3c-1.9-8.2-0.8-16.9,1.3-25c3.4-12.8,9-26.3,17.2-36.8C27.1,5.3,37.1,0,48.9,0h2.8C62,0,71.3,4.2,78.3,11.8c3.5,3.8,6.4,8.3,8.9,12.8c2.7,5,5.4,10.3,7.2,15.7c1.3,4.1,2.9,8.1,3.8,12.4c2.3,10.4,4.3,22.3-1.5,31.9c-3.1,5.1-8.2,8.6-13.7,10.4C76.3,97.3,69.8,97.5,62.8,97.5z',
		polygon:
			'50.5,0 73.5,5.7 94.3,37 100.4,71.6 91.7,91.7 67.5,97.4 30.1,96.9 8,90.8 0.4,69.8 7.2,33.3 28.8,5',
		width: 101,
		height: 98,
		image: require('@/assets/images/character-4.png'),
		resolution: 2,
	},
	{
		path: 'M59.6,100c-7.2,0-14.3,0-21.5-0.2c-6.8-0.2-13.5-0.1-20-2.4c-0.9-0.3-1.9-0.7-2.8-1.1c-3.6-1.5-6.9-3.6-9.4-6.7c-2.2-2.8-3.7-6.2-4.5-9.7c-1.8-7.8-0.8-16.1,1.2-23.7c3.2-12.1,8.5-25,16.3-34.9c6.8-8.7,16.3-13.7,27.5-13.7H49c9.8,0,18.6,3.9,25.2,11.1c3.3,3.6,6,7.8,8.4,12.2c2.6,4.7,5.1,9.8,6.8,14.9c1.3,3.9,2.7,7.7,3.6,11.8c2.2,9.8,4.1,21.1-1.4,30.3c-2.9,4.8-7.8,8.1-13,9.9C72.3,99.9,66.2,100,59.6,100z',
		polygon:
			'48.1,7.6 70.3,13.5 88.8,42.6 95.3,75 87.8,94.8 64.2,100 28.7,99.5 7.3,93.5 0.6,73.8 7.2,38.6 27.3,12.5',
		width: 96,
		height: 100,
		image: require('@/assets/images/character-5.png'),
		resolution: 2,
	},
	{
		path: 'M83.7,60.3c-0.9,27.5-20.8,40.2-41.4,39.6c-9.2-0.2-19.5-2.3-26.7-8C7.7,85.7,2.9,76.7,0.9,66.8c-3.2-13.6,10.5-44,12-47.8c1.4-3.5,2.7-6.7,5.2-9.5c1.1-1.2,2.3-2.4,3.5-3.4c3-2.4,6.4-4.2,10.1-5.2c3.5-1,7.1-0.9,10.7-0.6C48.3,0.8,54,2.9,58.2,6.8C62.9,11.2,82.8,40.2,83.7,60.3z',
		polygon:
			'36.3,0.1 56.8,3.9 74.8,36.4 83.7,58.7 78.1,84.9 60,98.5 30,99.3 7.1,85.4 0.5,56.7 9.7,34.9 18.6,7',
		width: 84,
		height: 100,
		image: require('@/assets/images/character-6.png'),
		resolution: 2,
	},
	{
		path: 'M72.1,0H29.5C13.7,0,0.8,12.8,0.8,28.7c0,15.8,12.8,28.7,28.7,28.7h42.6c15.8,0,28.7-12.8,28.7-28.7C100.8,12.8,88,0,72.1,0z',
		polygon:
			'74.3,0 93.7,7.9 100.8,28.7 94.7,49.1 73.9,57.4 28.4,57.3 8.4,49.7 0.8,28.7 7.4,8.1 27.6,0.1',
		width: 101,
		height: 58,
		image: require('@/assets/images/character-7.png'),
		resolution: 2,
	},
	{
		path: 'M75.6,0H24.4C10.9,0,0,10.9,0,24.4v42.4c0,13.5,10.9,24.4,24.4,24.4h51.1c13.5,0,24.4-10.9,24.4-24.4V24.4C100,10.9,89.1,0,75.6,0z',
		polygon:
			'0,22.5 5.9,6.5 22.1,0.1 77.8,0.1 93.4,5.9 100,22.5 100,67.6 93.9,84.8 76.6,91.1 22.4,91.1 6.5,85.1 0,68.6',
		width: 100,
		height: 92,
		image: require('@/assets/images/character-8.png'),
		resolution: 2,
	},
	{
		path: 'M62.4,97.3c-7.5,0-15.1,0-22.6-0.2c-7.1-0.2-14.2-0.1-21.1-2.5c-1-0.3-2-0.7-3-1.1c-3.8-1.6-7.3-3.8-9.8-7c-2.4-3-3.9-6.6-4.8-10.3C-0.8,68,0.3,59.2,2.4,51.2c3.4-12.8,8.9-26.3,17.1-36.8C26.8,5.3,36.7,0,48.5,0h2.8c10.3,0,19.6,4.1,26.5,11.7c3.5,3.8,6.4,8.2,8.8,12.8c2.7,5,5.4,10.3,7.2,15.7c1.3,4.1,2.8,8.1,3.8,12.4C100,63,102,74.9,96.2,84.6C93.1,89.6,88,93.1,82.5,95C75.8,97.2,69.4,97.3,62.4,97.3z',
		polygon:
			'50.1,0 73.1,5.7 93.9,37 100,71.6 91.3,91.7 67.1,97.4 29.7,96.9 7.6,90.8 0,69.8 6.8,33.3 28.4,5',
		width: 100,
		height: 98,
		image: require('@/assets/images/character-9.png'),
		resolution: 2,
	},
	{
		path: 'M67.5,75.2c-0.3,0.3-0.6,0.7-0.9,1c-2,2-4.6,3.4-7.3,4.2c-1.1,0.4-2.3,0.6-3.5,0.8c-1.7,0.2-3.4,0.2-5.2,0c-3.9-0.4-8.1-1.5-11.3-3.9c-4.9-3.6-8.5-8.7-12.4-13.3c-3.3-4-6.6-8-9.9-12.1c-5.8-7.3-12.7-15-16-23.8C-2.1,19.7,1.9,9.7,9.3,4.7C13.9,1.7,19.3,0,24.8,0h47.4c3.3,0,6.5-0.1,9.7,0.6c4.2,0.9,8.1,3,11.2,6.1c3.1,3.1,5.2,6.9,6.2,11.2c0.7,3.1,0.9,5.9,0.1,9.1c-0.8,3.1-2.1,6-3.8,8.6c-7.9,12-15.8,24.1-24.6,35.4c-0.9,1.1-1.8,2.3-2.7,3.3C68.1,74.6,67.8,74.9,67.5,75.2z',
		polygon:
			'0,22 5.6,5.4 22,0.2 78,0.2 94.1,6.6 99.9,21.7 95.2,36.4 65.5,78 52.5,81.3 37.3,77.1 3,32.6',
		width: 100,
		height: 82,
		image: require('@/assets/images/character-10.png'),
		resolution: 2,
	},
	{
		path: 'M51,0H15.5C6.9,0,0,6.9,0,15.5v69C0,93.1,6.9,100,15.5,100H51c8.6,0,15.5-6.9,15.5-15.5v-69C66.5,6.9,59.6,0,51,0z',
		polygon:
			'0,15.5 3,4.8 14.7,0 52.4,0 62.6,4.1 66.5,15.1 66.5,84.1 62.8,96 52.6,100 13.3,100 2.5,94.6 0,85.8',
		width: 67,
		height: 100,
		image: require('@/assets/images/character-11.png'),
		resolution: 2,
	},
	{
		path: 'M58.6,0h-16c-23.2,0-42,18.8-42,42v0.2c0,23.2,18.8,42,42,42h16c23.2,0,42-18.8,42-42V42C100.6,18.8,81.8,0,58.6,0z',
		polygon:
			'0.3,41.8 8.5,14.2 35,0.1 66.7,0 92.6,13 100.6,42.5 93.5,69 66.9,83.8 34.6,84.1 8.9,71',
		width: 101,
		height: 85,
		image: require('@/assets/images/character-12.png'),
		resolution: 2,
	},
	{
		path: 'M50.6,98.1c27.6,0,50-22,50-49.1C100.5,22,78.2,0,50.6,0c-27.6,0-50,22-50,49.1C0.6,76.2,22.9,98.1,50.6,98.1z',
		polygon:
			'0,49.3 13.5,11.9 50.9,0 88,12.4 101,49 90.5,83.3 50.6,98.7 12.3,84.6',
		width: 101,
		height: 99,
		image: require('@/assets/images/character-13.png'),
		resolution: 2,
	},
	{
		path: 'M62.9,97.5c-7.6,0-15.1,0-22.7-0.2c-7.2-0.2-14.3-0.1-21.1-2.5c-1-0.3-2-0.7-3-1.1c-3.8-1.6-7.3-3.8-9.9-7c-2.4-3-3.9-6.6-4.8-10.3c-1.9-8.2-0.8-16.9,1.3-25C6.2,38.5,11.8,25,20,14.5C27.2,5.3,37.2,0,49,0h2.8c10.3,0,19.6,4.2,26.6,11.8c3.5,3.8,6.4,8.3,8.9,12.8c2.7,5,5.4,10.3,7.2,15.7c1.3,4.1,2.9,8.1,3.8,12.4c2.3,10.4,4.3,22.3-1.5,31.9c-3.1,5.1-8.2,8.6-13.7,10.4C76.4,97.3,69.9,97.5,62.9,97.5z',
		polygon:
			'50.6,0 73.6,5.7 94.3,37.1 100.5,71.6 91.8,91.8 67.6,97.5 30.2,96.9 8.1,90.8 0.5,69.8 7.2,33.3 28.9,5.1',
		width: 101,
		height: 98,
		image: require('@/assets/images/character-14.png'),
		resolution: 2,
	},
];
