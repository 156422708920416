// "translate" a base 10 number by replacing each digit with the translated symbol
function translateNumber(value, digits) {
	value = String(value).split('');

	return value.reduce(
		(string, digit) => string + digits[parseInt(digit)],
		''
	);
}
//
export default {
	install(Vue, translations) {
		const NUMBERS = (translations._numbers || '0123456789').split('');

		Vue.prototype.$l10n = function (string, ...values) {
			if (typeof string === 'number') {
				return translateNumber(string, NUMBERS);
			}

			string = translations[string] || string;
			if (values.length === 0) {
				return string;
			}

			return this.$print(string, ...values);
		};
	},
};
