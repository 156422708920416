<template>
	<div class="demosurvey">
		<Transition name="fade">
			<AppScreen
				v-if="step === $options.STEP_INTRO"
				key="content"
				name="demosurvey"
				class="demosurvey__intro"
			>
				<BubbleBox @hidden="handleChoice">
					<template v-slot="{ hide }">
						<h2>{{ heading }}</h2>

						<BaseButton
							v-if="whyContent"
							name="why"
							is-minor
							@click="showModal('surveyWhy')"
						>
							{{ whyText }}
						</BaseButton>

						<div class="demosurvey__buttons">
							<BaseButton
								name="consent"
								@click="(consent = true), hide()"
							>
								{{ $l10n('demosurvey-consent') }}
							</BaseButton>
							<BaseButton
								name="abstain"
								@click="(consent = false), hide()"
							>
								{{ $l10n('demosurvey-abstain') }}
							</BaseButton>
						</div>
					</template>
				</BubbleBox>
			</AppScreen>
			<template v-for="(question, index) in questions">
				<QuestionScreen
					v-if="step === index"
					:key="question.id"
					v-bind="question"
					@submit="nextQuestion"
				/>
			</template>
			<AppScreen
				v-if="step === $options.STEP_OUTRO"
				key="feedback"
				class="demosurvey__feedback"
			>
				<BubbleFace
					:label="$l10n('survey-thanks')"
					:duration="2000"
					@hidden="$emit('done')"
				/>
			</AppScreen>
		</Transition>
		<BaseModal v-if="!!whyContent" name="surveyWhy" :content="whyContent" />
	</div>
</template>

<script>
import AppScreen from '@/components/AppScreen.vue';
import BubbleBox from '@/components/BubbleBox.vue';
import BubbleFace from '@/components/BubbleFace.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseModal from '@/components/BaseModal.vue';
import QuestionScreen from '@/components/QuestionScreen.vue';

const STEP_INTRO = -1;
const STEP_OUTRO = -2;

export default {
	STEP_INTRO,
	STEP_OUTRO,

	components: {
		AppScreen,
		BubbleBox,
		BubbleFace,
		BaseButton,
		BaseModal,
		QuestionScreen,
	},
	inheritAttrs: false,
	props: {
		name: {
			type: String,
			required: true,
		},
		heading: {
			type: String,
			required: true,
		},
		whyText: {
			type: String,
			required: true,
		},
		whyContent: {
			type: String,
			required: true,
		},
		questions: {
			type: Array,
			default: () => [],
		},
	},
	emits: ['done'],
	data() {
		return {
			step: STEP_INTRO,
			consent: false,
		};
	},
	methods: {
		handleChoice() {
			if (this.consent) {
				this.startSurvey();
			} else {
				this.$emit('done');
			}
		},
		startSurvey() {
			this.step = 0;
		},
		nextQuestion() {
			if (this.step < this.questions.length - 1) {
				this.step++;
			} else {
				this.step = STEP_OUTRO;
			}
		},
	},
};
</script>

<style lang="scss">
.demosurvey {
	&__intro {
		h2 {
			font-size: $font-size-heading;
		}
	}
	&__buttons {
		.button {
			margin: 0.25em 0;
		}
	}
}
</style>
