<template>
	<Transition
		@before-enter="$emit('showing')"
		@after-enter="$emit('shown'), maybeHide()"
		@before-leave="$emit('hiding')"
		@after-leave="$emit('hidden')"
	>
		<div v-show="visible">
			<slot :hide="hide" />
		</div>
	</Transition>
</template>

<script>
export default {
	props: {
		autoHide: {
			type: [Number, Boolean],
			default: null,
		},
	},
	data() {
		return {
			visible: false,
		};
	},
	mounted() {
		this.$nextTick(() => (this.visible = true));

		if (typeof this.autoHide === 'number') {
			setTimeout(this.hide, this.autoHide);
		}
	},
	methods: {
		hide() {
			this.visible = false;
		},
		maybeHide() {
			if (this.autoHide === true) {
				this.hide();
			}
		},
	},
};
</script>
