export default {
	namespaced: true,
	state: {
		active: [],
	},
	mutations: {
		show(state, name) {
			if (!state.active.includes(name)) {
				state.active.push(name);
			}
		},
		hide(state, name) {
			const active = [...state.active];
			const index = active.indexOf(name);
			if (index >= 0) {
				active.splice(index, 1);
				state.active = active;
			}
		},
		reset(state) {
			state.active = [];
		},
	},
};
