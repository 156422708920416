import Vue from 'vue';
import { default as Vuex, mapMutations } from 'vuex';

import audio from './audio';
import modals from './modals';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {},
	mutations: {},
	actions: {},
	modules: {
		audio,
		modals,
	},
});

Vue.mixin({
	methods: mapMutations('modals', {
		showModal: 'show',
		hideModal: 'hide',
	}),
});
