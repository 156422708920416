import { initializeApp } from 'firebase/app';
import {
	getFirestore,
	collection,
	doc,
	addDoc,
	updateDoc,
	serverTimestamp,
} from 'firebase/firestore';

class Logger {
	constructor(db) {
		this.db = db;
		this.step_counter = 0;
		this.start_time = new Date();
	}

	async init(surveyName) {
		const docRef = await addDoc(collection(this.db, 'sessions'), {
			survey_name: surveyName + '-click',
			version: window.location.hash.replace('#', ''),
			language: document.documentElement.lang,
			url: window.location.href,
			created: serverTimestamp(),
		});
		this.session = docRef.id;
		return this.session;
	}

	async log(collection_name, data) {
		const docRef = collection(
			this.db,
			'sessions',
			this.session,
			collection_name
		);
		await addDoc(docRef, { step_counter: this.step_counter, ...data });
		await updateDoc(doc(this.db, 'sessions', this.session), {
			duration_in_seconds: new Date() - this.start_time,
		});
		this.step_counter++;
	}

	async setLanguage(language) {
		if (this.session) {
			const docRef = doc(this.db, 'sessions', this.session);
			await updateDoc(docRef, { language });
		}
	}

	answer({
		question_type,
		question_name,
		answer_text,
		duration_in_seconds = 0,
		result = '',
	}) {
		if (typeof result === 'boolean') {
			result = result ? 'correct' : 'incorrect';
		}

		return this.log('answers', {
			question_type,
			question_name,
			answer_text,
			duration_in_seconds,
			result,
		});
	}

	demographic({ question_name, answers }) {
		return this.log('demographics', {
			question_name,
			answers,
		});
	}

	event({ event_type, target, location = '', question_name = '' }) {
		return this.log('events', {
			event_type,
			target,
			location,
			question_name,
		});
	}
}

export default {
	install(Vue, surveyName) {
		initializeApp({
			apiKey: process.env.VUE_APP_FIRESTORE_APIKEY,
			projectId: process.env.VUE_APP_FIRESTORE_PROJECT,
		});
		const db = getFirestore();
		const logger = new Logger(db);

		logger.init(surveyName);
		logger.setLanguage(document.documentElement.lang);

		Vue.prototype.$log = logger;
	},
};
