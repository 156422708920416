import Vue from 'vue';

import config from './config.json';
document.documentElement.lang = config.language;

const fontImport = document.createElement('link');
fontImport.rel = 'stylesheet';
if (document.documentElement.lang === 'my') {
	fontImport.href =
		'https://fonts.googleapis.com/css2?family=Noto+Sans+Myanmar:wght@400;600;800&display=swap';
} else {
	fontImport.href =
		'https://fonts.googleapis.com/css2?family=Fredoka+One&family=Manrope:wght@400;600;800&display=swap';
}
document.head.appendChild(fontImport);

import './assets/styles.scss';

import App from './App.vue';
import store from './store';

import print from './mixins/print';
import l10n from './mixins/l10n';
import queue from './mixins/queue';
import uid from './mixins/uid';
import sounds from './mixins/sounds';
import logging from './mixins/logging';

const BASE_URL = process.env.VUE_APP_BASE_URL || process.env.BASE_URL;

Vue.use(print);
Vue.use(l10n, config.translations);
Vue.use(queue);
Vue.use(uid);
Vue.use(sounds, BASE_URL + 'effects.mp3', [
	['button', '0:00.000', '0:00.500'],
	['correct', '0:01.00', '0:02.500'],
	['answer', '0:03.000', '0:03.500'],
	['drag', '0:04.000', '0:05.000'],
	['graph-up1', '0:06.000', '0:06.500'],
	['graph-up2', '0:07.000', '0:07.500'],
]);
Vue.use(logging, config.name);

Vue.config.productionTip = false;

new Vue({
	store,
	render: h =>
		h(App, {
			props: {
				...config,
				music_source: BASE_URL + 'music.mp3',
			},
		}),
}).$mount('#app');
