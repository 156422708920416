<template>
	<TransitionWrapper
		:class="['bubble-box', `is-color-${color}`]"
		v-on="$listeners"
	>
		<template #default="slotProps">
			<slot v-bind="slotProps" />
		</template>
	</TransitionWrapper>
</template>

<script>
import TransitionWrapper from '@/components/TransitionWrapper.vue';

export default {
	components: {
		TransitionWrapper,
	},
	props: {
		color: {
			type: String,
			default: 'purple',
		},
	},
};
</script>

<style lang="scss">
.bubble-box {
	position: relative;
	display: flex;
	margin: auto;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	max-width: rem(360);
	transform: rotate(-3.5deg) scale(1);

	&::before {
		content: '';
		position: absolute;
		z-index: -1;
		inset: 50%;
		@include size(630);
		border-radius: 50%;
		transform: translateX(-50%) translateY(-50%);
	}

	@each $name, $color in $color-options {
		&.is-color-#{$name}::before {
			background-color: $color;
		}
	}

	&.v-enter-active {
		transition: transform 0.5s 0.5s cubic-bezier(0.505, 0.545, 0.33, 1.6);
	}
	&.v-leave-active {
		transition: transform 0.5s 0.2s cubic-bezier(0.67, -0.6, 0.495, 0.455);
	}
	&.v-enter,
	&.v-leave-to {
		transform: rotate(360deg) scale(0);
	}
}
</style>
