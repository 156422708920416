<template>
	<div class="datapoint-summary">
		<h4 v-html="title" />
		<dl class="datapoint-stats">
			<div class="datapoint-stat">
				<dt>{{ previous_label }}</dt>
				<dd>{{ $l10n('percent', $l10n(previous)) }}</dd>
			</div>
			<div class="datapoint-stat">
				<dt>{{ current_label }}</dt>
				<dd>{{ $l10n('percent', $l10n(current)) }}</dd>
			</div>
			<div class="datapoint-stat">
				<dt>{{ $l10n('datapoint-diff') }}</dt>
				<dd>{{ changeText }}</dd>
			</div>
		</dl>
	</div>
</template>

<script>
export default {
	inheritAttrs: false,
	props: {
		content: {
			type: String,
			required: true,
		},
		current: {
			type: Number,
			required: true,
		},
		current_label: {
			type: String,
			required: true,
		},
		previous: {
			type: Number,
			required: true,
		},
		previous_label: {
			type: String,
			required: true,
		},
	},
	computed: {
		title() {
			const [, , title] = this.content.match(/<h(\d)>(.+?)<\/h\1>/);

			return title;
		},
		changeText() {
			const change = this.current - this.previous;
			return this.$l10n(
				change > 0 ? 'increase' : 'decrease',
				this.$l10n(Math.abs(change))
			);
		},
	},
};
</script>

<style lang="scss">
.datapoint-summary {
	margin: rem(24) 0;
}
.datapoint-stat {
	display: flex;
	justify-content: space-between;
	padding: 0.5em 0;

	&:not(:first-child) {
		border-top: 1px solid rgba(#000, 0.5);
	}
}
</style>
