export default {
	install(Vue) {
		// Return string with {x} instances replaced with corresponding numbered values
		Vue.prototype.$print = function (string, ...values) {
			if (values.length > 0) {
				string = string.replace(/\{(\d+)\}/g, function (match, number) {
					number = parseInt(number) - 1;
					return values[number] || match;
				});
			}

			return string;
		};
	},
};
