<template>
	<TransitionWrapper
		:class="['bubble-face', `is-color-${color}`]"
		:auto-hide="duration"
		v-on="$listeners"
	>
		<div class="shape" />
		<p v-if="label">{{ label }}</p>
	</TransitionWrapper>
</template>

<script>
import TransitionWrapper from '@/components/TransitionWrapper.vue';

export default {
	components: {
		TransitionWrapper,
	},
	props: {
		label: {
			type: String,
			default: null,
		},
		color: {
			type: String,
			default: 'purple',
		},
		duration: {
			type: Number,
			default: 1000,
		},
	},
};
</script>

<style lang="scss">
.bubble-face {
	.shape {
		width: 100%;
		max-width: rem(360);
		background: center no-repeat;
		background-size: 100%;
		border-radius: 50%;
		@include with-aspect($use: 'after');
	}

	@each $name, $color in $color-options {
		&.is-color-#{$name}::before {
			background-color: $color;
			background-image: url('../assets/images/face-#{$name}.svg');
		}
	}

	p {
		font-family: $font-family-heading;
		font-size: $font-size-heading;
		font-weight: $heading-weight;
		transform: rotate(-2deg) scale(1);
	}

	&.v-enter-active,
	&.v-leave-active {
		transition: zoom 0.8s;
	}
	&.v-enter-active {
		.shape,
		p {
			transition: transform 0.5s cubic-bezier(0.505, 0.545, 0.495, 1.6);
		}
		p {
			transition-duration: 0.3s;
		}
	}
	&.v-leave-active {
		.shape,
		p {
			transition: transform 0.5s 0.2s
				cubic-bezier(0.495, -0.6, 0.505, 0.455);
		}
		p {
			transition-duration: 0.3s;
		}
	}
	&.v-enter,
	&.v-leave-to {
		zoom: 0;

		.shape {
			transform: rotate(360deg) scale(0);
		}
		p {
			transform: rotate(-2deg) scale(0);
		}
	}
}
</style>
