import { Body, Svg, Bodies } from 'matter-js';

export default class MatterShape {
	scaleX = 1;
	scaleY = 1;
	initState = {};
	metadata = {};

	constructor(shape, config, metadata = {}) {
		let vertices;
		if (shape.polygon) {
			vertices = shape.polygon.split(' ').map(pair => {
				const [x, y] = pair.split(',');
				return {
					x: parseFloat(x),
					y: parseFloat(y),
				};
			});
		} else {
			const path = document.createElementNS(
				'http://www.w3.org/2000/svg',
				'path'
			);
			path.setAttribute('d', shape.path);
			vertices = Svg.pathToVertices(path, 50);
		}

		this.initState = config;
		const {
			x = 0,
			y = 0,
			scaleX = 1,
			scaleY = 1,
			opacity = 1,
			...options
		} = config;

		this.shape = shape;
		this.body = Bodies.fromVertices(x, y, vertices, {
			render: {
				opacity,
				sprite: {
					texture: shape.image,
					xScale: 1 / shape.resolution,
					yScale: 1 / shape.resolution,
				},
			},
			...options,
		});

		this.scaleAbsolute(scaleX, scaleY);

		this.metadata = metadata;
	}

	reset() {
		const {
			x = 0,
			y = 0,
			scaleX = 1,
			scaleY = 1,
			opacity = 1,
			isStatic,
			...options
		} = this.initState;

		this.scaleAbsolute(scaleX, scaleY);
		this.scaleSpriteAbsolute(scaleX, scaleY);
		this.setPosition({ x, y });
		this.setStatic(isStatic);
		this.scaleAbsolute(scaleX, scaleY);
		this.setOpacity(opacity);
		Body.set(this.body, options);
	}

	scale(scaleX, scaleY) {
		scaleY = scaleY || scaleX;
		this.scaleX *= scaleX;
		this.scaleY *= scaleY;

		Body.scale(this.body, scaleX, scaleY);
		this.scaleSprite(scaleX, scaleY);
	}

	scaleSprite(scaleX, scaleY) {
		scaleY = scaleY || scaleX;

		const {
			render: { sprite },
			bounds,
			position,
		} = this.body;

		sprite.xScale *= scaleX;
		sprite.yScale *= scaleY;
		sprite.xOffset =
			-(bounds.min.x - position.x) / (bounds.max.x - bounds.min.x);
		sprite.yOffset =
			-(bounds.min.y - position.y) / (bounds.max.y - bounds.min.y);
	}

	scaleAbsolute(scaleX, scaleY) {
		scaleY = scaleY || scaleX;

		this.scale(scaleX / this.scaleX, scaleY / this.scaleY);
	}

	scaleSpriteAbsolute(scaleX, scaleY) {
		scaleY = scaleY || scaleX;
		scaleX /= this.shape.resolution;
		scaleY /= this.shape.resolution;

		const {
			render: { sprite },
		} = this.body;

		this.scaleSprite(scaleX / sprite.xScale, scaleY / sprite.yScale);
	}

	setOpacity(opacity) {
		this.body.render.opacity = opacity;
	}

	setPosition(point) {
		Body.setPosition(this.body, point);
	}

	setStatic(isStatic) {
		Body.setStatic(this.body, isStatic);
	}
}
