var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppScreen',{key:"content",staticClass:"interrupt",attrs:{"name":"interrupt"}},[_c('BubbleBox',{attrs:{"color":_vm.color},on:{"hidden":function($event){return _vm.submitAnswer(_vm.selected)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var _obj;

var hide = ref.hide;return [(_vm.placement)?_c('p',[_vm._v(" "+_vm._s(_vm.$l10n( 'question-placement', _vm.$l10n(_vm.placement.current), _vm.$l10n(_vm.placement.total) ))+" ")]):_vm._e(),_c('div',{domProps:{"innerHTML":_vm._s(_vm.content)}}),_c('div',_vm._b({class:( _obj = {
					interrupt__buttons: true
				}, _obj[("is-layout-" + (_vm.config.layout))] = !!_vm.config.layout, _obj )},'div',_vm.buttonsConfig,false),_vm._l((_vm.options),function(option){
				var _obj;
return _c('BaseButton',{key:option.value,class:( _obj = {}, _obj[("is-style-" + (_vm.config.style))] = !!_vm.config.style, _obj ),attrs:{"icon":option.icon},on:{"click":function($event){(_vm.selected = option.value), hide()}}},[_vm._v(" "+_vm._s(option.label)+" ")])}),1)]}}])}),_c('BaseButton',{staticClass:"interrupt__skip",attrs:{"is-minor":""},on:{"click":function($event){return _vm.submitAnswer('abstain')}}},[_vm._v(" "+_vm._s(_vm.$l10n('skip-question'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }