<template>
	<AppScreen class="home">
		<div class="home__content" v-html="content" />
		<BaseButton v-if="startClick" @click="maybeFinish" is-black>
			{{ $l10n('click-to-start') }}
		</BaseButton>
		<BaseSlider
			v-if="!startClick"
			v-model="count"
			:max="10"
			:label="$l10n('slide-to-start')"
			:hide-label="count > 5"
			:disabled="complete"
			@dragged="maybeFinish"
		/>
		<GravityCanvas
			:resolution="2"
			:count="10 + count"
			:shapes="shapes"
			:item-scale="2"
		/>
	</AppScreen>
</template>

<script>
import AppScreen from '@/components/AppScreen.vue';
import GravityCanvas from '@/components/GravityCanvas.vue';
import BaseSlider from '@/components/BaseSlider.vue';
import BaseButton from '@/components/BaseButton.vue';
import SHAPES from '@/utilities/shapes';

export default {
	components: {
		AppScreen,
		GravityCanvas,
		BaseSlider,
		BaseButton,
	},
	inheritAttrs: false,
	props: {
		content: {
			type: String,
			required: true,
		},
	},
	emits: ['done'],
	data() {
		const startClick = process.env.VUE_APP_START_CLICK === 'true';
		return {
			startClick,
			count: 0,
			shapes: SHAPES,
			complete: false,
		};
	},
	methods: {
		maybeFinish() {
			this.$log.event({
				event_type: 'start',
				target: this.count.toString(),
				location: 'home',
			});

			this.complete = true;
			setTimeout(() => {
				this.$emit('done');
			}, 300);
		},
	},
};
</script>

<style lang="scss">
.home {
	padding: rem(40);

	&__content {
		margin: auto;
	}

	h1 {
		font-size: $font-size-jumbo;
	}

	.slider {
		&-bar {
			background: $color-off-white;
		}

		&-fill {
			box-shadow: none;
			background: none;
		}
	}
}
</style>
