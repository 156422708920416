<template>
	<canvas ref="canvas" class="gravity-canvas" />
</template>

<script>
import { Engine, Render, Runner, Bodies, Composite } from 'matter-js';
import MatterShape from '@/utilities/matter-shape';
import animate from '@/utilities/animate';

const SHAPE_SIZE = 100;

function getShape(shapes, index) {
	if (typeof shapes === 'function') {
		return shapes(index);
	}

	return shapes[Math.floor(Math.random() * shapes.length)];
}

export default {
	inheritAttrs: false,
	props: {
		count: {
			type: Number,
			default: 0,
		},
		resolution: {
			type: Number,
			default: 1,
		},
		itemScale: {
			type: Number,
			default: 1,
		},
		shapes: {
			type: [Array, Function],
			required: true,
		},
	},
	watch: {
		count(newValue, oldValue) {
			newValue = parseInt(newValue);
			oldValue = parseInt(oldValue);
			const diff = Math.abs(newValue - oldValue);

			if (!diff) {
				return;
			}

			if (newValue > oldValue) {
				this.addShapes(diff);
			} else {
				this.removeShapes(diff);
			}
		},
	},
	mounted() {
		// Portrait = 1x2 canvas, Landscape = square
		// This (hopefully) ensures shapes drop from off-screen
		this.isPortrait = window.innerHeight > window.innerWidth;
		this.width = Math.max(window.innerWidth, window.innerHeight);
		this.height = this.isPortrait ? this.width * 2 : this.width;

		// Item should be 1/8th the width, BUT
		// no bigger than the original image size (assuming retina)
		this.itemSize = Math.min(
			this.width / (8 / this.itemScale),
			SHAPE_SIZE * this.itemScale
		);

		// Setup engine and renderer
		this.engine = Engine.create();
		this.render = Render.create({
			canvas: this.$refs.canvas,
			engine: this.engine,
			options: {
				pixelRatio: this.resolution,
				width: this.width,
				height: this.height,
				background: '#FFF5E5',
				wireframes: false, // why this is on by default is beyond me
			},
		});

		// Add walls, off canvas
		const wall = 100; // need a size otherwise they clip through
		Composite.add(this.engine.world, [
			// Left
			Bodies.rectangle(wall / -2, this.height / 2, wall, this.height, {
				isStatic: true,
				render: {
					opacity: 0,
				},
			}),
			// Right
			Bodies.rectangle(
				this.width + wall / 2,
				this.height / 2,
				wall,
				this.height,
				{
					isStatic: true,
					render: {
						opacity: 0,
					},
				}
			),
			// Bottom
			Bodies.rectangle(
				this.width / 2,
				this.height + wall / 2,
				this.width,
				wall,
				{
					isStatic: true,
					render: {
						opacity: 0,
					},
				}
			),
		]);

		// Run the renderer
		Render.run(this.render);

		// Create runner
		this.runner = Runner.create();

		// Run the engine
		Runner.run(this.runner, this.engine);

		this.items = [];
		this.addShapes(this.count);
	},
	destroyed() {
		// Clean up???
		Render.stop(this.render);
		Runner.stop(this.runner);
		Engine.clear(this.engine);
	},
	methods: {
		addShape(index = null) {
			const shape = getShape(this.shapes, index);

			const size = Math.max(shape.width, shape.height);
			const scale = this.itemSize / size;

			const item = new MatterShape(shape, {
				// Centered off-canvas to start, static
				x: this.width / 2,
				y: (shape.height * scale) / -2,
				scaleX: scale,
				scaleY: scale,
				angle: Math.random() * Math.PI * 2,
			});

			this.items.push(item);
			Composite.add(this.engine.world, item.body);

			return item;
		},
		addShapes(count) {
			for (let i = 0; i < count; i++) {
				this.addShape(i);
			}
		},
		removeShape() {
			const item = this.items.pop();
			item.setStatic(true);
			animate({
				step: progress => {
					item.scaleSpriteAbsolute(item.scaleX * (1 + progress));
					item.setOpacity(1 - progress);
				},
				done: () => {
					Composite.remove(this.engine.world, item.body);
				},
				duration: 500,
			});
		},
		removeShapes(count) {
			for (let i = 0; i < count; i++) {
				this.removeShape();
			}
		},
	},
};
</script>

<style lang="scss">
.gravity-canvas {
	position: absolute;
	z-index: -1;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	width: 100% !important;
	height: auto !important;
}
</style>
