<template>
	<div class="screen">
		<slot />
	</div>
</template>

<script>
export default {
	inheritAttrs: false,
	props: {
		name: {
			type: String,
			default: null,
		},
	},
	provide() {
		return {
			parentView: this.name,
		};
	},
};
</script>

<style lang="scss">
.screen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	isolation: isolate;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	background: $color-beige;
	padding: $screen-padding;
	padding-top: $toolbar-spacing;
	overflow: hidden auto;

	&.has-toolbar {
		padding-top: $toolbar-spacing;
	}

	.screen {
		position: relative;
		width: 100vw;
		height: 100%;
	}
}
</style>
