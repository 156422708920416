<template>
	<AppScreen key="content" name="interrupt" class="interrupt">
		<BubbleBox :color="color" @hidden="submitAnswer(selected)">
			<template v-slot="{ hide }">
				<p v-if="placement">
					{{
						$l10n(
							'question-placement',
							$l10n(placement.current),
							$l10n(placement.total)
						)
					}}
				</p>

				<div v-html="content" />

				<div
					:class="{
						interrupt__buttons: true,
						[`is-layout-${config.layout}`]: !!config.layout,
					}"
					v-bind="buttonsConfig"
				>
					<BaseButton
						v-for="option in options"
						:key="option.value"
						:icon="option.icon"
						:class="{
							[`is-style-${config.style}`]: !!config.style,
						}"
						@click="(selected = option.value), hide()"
					>
						{{ option.label }}
					</BaseButton>
				</div>
			</template>
		</BubbleBox>
		<BaseButton
			is-minor
			class="interrupt__skip"
			@click="submitAnswer('abstain')"
		>
			{{ $l10n('skip-question') }}
		</BaseButton>
	</AppScreen>
</template>

<script>
import { omit } from 'lodash';
import AppScreen from '@/components/AppScreen.vue';
import BaseButton from '@/components/BaseButton.vue';
import BubbleBox from '@/components/BubbleBox.vue';

export default {
	components: {
		AppScreen,
		BaseButton,
		BubbleBox,
	},
	inheritAttrs: false,
	props: {
		name: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			required: true,
		},
		content: {
			type: String,
			required: true,
		},
		options: {
			type: Array,
			required: true,
		},
		color: {
			type: String,
			default: 'purple',
		},
		config: {
			type: Object,
			default: () => ({}),
		},
		placement: {
			type: Object,
			default: null,
		},
	},
	emits: ['done'],
	data() {
		return {
			selected: null,
		};
	},
	computed: {
		buttonsConfig() {
			const data = {};

			const config = omit(this.config, ['layout', 'style']);
			for (const key in config) {
				data[`data-${key}`] = config[key];
			}

			return data;
		},
	},
	methods: {
		submitAnswer(answer) {
			this.$log.answer({
				question_type: this.type,
				question_name: this.name,
				answer_text: answer.toString(),
			});

			this.$emit('done');
		},
	},
};
</script>

<style lang="scss">
.interrupt {
	h2 {
		font-family: $font-family-body;
		font-size: $font-size-heading;
		font-weight: $font-weight-bold;
	}

	.bubble-box {
		font-weight: $font-weight-bold;
	}

	&__buttons {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		margin-top: rem(30);

		.button {
			margin: 0 0 rem(10);

			+ .button {
				margin-left: rem(10);
			}
		}

		.icon-only .button__inner {
			@include size(44);
		}

		&.is-layout-range {
			position: relative;
			padding-top: rem(30);
			margin-top: rem(10);

			&::before,
			&::after {
				position: absolute;
				top: 0;
				font-size: rem(12);
				font-weight: $font-weight-bold;
			}

			&::before {
				left: 0;
				content: attr(data-leftlabel);
			}
			&::after {
				right: 0;
				content: attr(data-rightlabel);
			}
		}
	}

	&__skip {
		position: relative;
		z-index: 1;
		margin-top: auto;
	}

	.screen.v-leave-active,
	.screen.v-enter-active {
		overflow: hidden;
	}
}
</style>
