<template>
	<TransitionWrapper
		class="bounce-graphic-wrapper"
		auto-hide
		v-on="$listeners"
	>
		<img
			:src="require(`@/assets/images/${image}`)"
			:alt="label"
			class="bounce-graphic"
		/>
	</TransitionWrapper>
</template>

<script>
import TransitionWrapper from '@/components/TransitionWrapper.vue';

export default {
	components: {
		TransitionWrapper,
	},
	props: {
		image: {
			type: String,
			required: true,
		},
		label: {
			type: [String, Number],
			default: null,
		},
	},
};
</script>

<style lang="scss">
@keyframes bounceWrapperIn {
	0% {
		transform: translateY(calc(50vh + 50%));
	}
	70%,
	100% {
		transform: translateY(0);
	}
}
@keyframes bounceWrapperOut {
	0%,
	30% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(calc(50vh + 50%));
	}
}

.bounce-graphic-wrapper.v-enter-active {
	animation: bounceWrapperIn 1s 0.4s ease-in-out both;
}
.bounce-graphic-wrapper.v-leave-active {
	animation: bounceWrapperOut 1s ease-in-out both;
}

@keyframes bounceGraphicIn {
	0% {
		transform: rotate(-30deg);
	}
	100% {
		transform: rotate(0deg);
	}
}
@keyframes bounceGraphicOut {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(30deg);
	}
}

.bounce-graphic {
	display: block;

	.v-enter-active & {
		animation: bounceGraphicIn 1s 0.4s linear both;
	}

	.v-leave-active & {
		animation: bounceGraphicOut 1s linear both;
	}
}
</style>
