export default function animate({ step, done, duration }) {
	const start = performance.now();

	let stopped = false;
	function tick(timestamp) {
		if (stopped) {
			step(1);
			done && done();
			return;
		}

		const delta = Math.max(Math.min(timestamp - start, duration), 0);
		const progress = delta / duration;

		step(progress);

		if (progress < 1) {
			requestAnimationFrame(tick);
		} else {
			done && done();
		}
	}

	requestAnimationFrame(tick);

	return () => {
		stopped = true;
	};
}
